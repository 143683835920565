
function create_ID(color) {   // Replace spaces with dashes, convert to lowercase, and remove all non-alphanumeric characters
	return color.replace(/\s+/g, '-').toLowerCase().replace(/[^a-z0-9-]/g, '').replace('--', '-');
}
function set_Active_Swatch(swatch_id, scope, swatches, layout) {
	var
		colorID = swatch_id.replace('tyt-', ''),
		currentImage = document.querySelector(`${scope} #tyt-img-${colorID}`),
		desc = document.querySelector(`${scope} #tyt-description-${colorID}`),
		label = currentImage.getAttribute('data-pill-label'),
		eachImage = document.querySelectorAll(`${scope} .tyt-img`),
		description = document.querySelectorAll(`${scope} .tyt-description`),
		eachSwatch = document.querySelectorAll('.swatch'),
		activeSwatch = document.querySelector(`${scope} .swatch.active`),
		pillLabel = document.querySelector(`${scope} #pill-label`);

	swatches.forEach(swatch => swatch.classList.remove('active'));
	eachImage.forEach(img => {
		img.classList.remove('!opacity-100');
	});
	description.forEach(desc => {
		desc.classList.remove('!block');
	});
	document.getElementById(swatch_id).classList.add('active');
	eachSwatch.forEach(swatch => {
		swatch.setAttribute('aria-current', 'false');
	});
	activeSwatch.setAttribute('aria-current', 'true');
	currentImage.classList.add('!opacity-100');
	if (layout !== 'home') desc.classList.add('!block')
		pillLabel.innerHTML = label;
}
function start_Slideshow(scope, layout, autoPlay, int) {
	var swatches = document.querySelectorAll(`${scope} .swatch`);
	// cycle through swatches
	// pause for ${int ?? 3000} ms each time
	let i = 0;
	const interval = setInterval(() => {
		if (i < swatches.length) {
			set_Active_Swatch(swatches[i].id, `${scope}`, swatches, layout);
			i++;
		} else {
			i = 0;
		}
	}, int);
	// Set swatch on click, then clear interval
	swatches.forEach(swatch => {
		//console.log(swatch.id);
		swatch.addEventListener('click', e => {
			set_Active_Swatch(e.target.id, `${scope}`, swatches, layout);
			clearInterval(interval);
		});
	});
}
window.__Slideshow = function (colors, layout, uid, thumbStyle, autoPlay, interval, floating_image_label, button_label, wrapper_for_thumbnail_button, thumbnail_button, wrapper_for_swatch_button, swatch_button, token) {
	//console.log("SLIDESHOW ID: " + uid)
	var
		pillLabel = `<div id="pill-label" class="${floating_image_label}">${colors[0]['name']}</div>`,

		// === IMAGES:
		images = colors.map((color, idx) => {
			let colorID = `${create_ID(color['name'])}`,
				img = `
					<img
						src="/image?f=tyt-${colorID}.jpg&w=782&q=70&t=${token}"
						height="358" width="782"
						class="
							${idx === 0 ? 'relative !opacity-100' : 'absolute top-0 left-0 right-0 w-full'}
							${layout === 'inside' ? 'h-72 object-cover' : ''}
							tyt-img duration-500 w-full rounded-lg "
						id="tyt-img-${colorID}-${uid}"
						data-pill-label="${color['name']}"
						alt="${color['alt_text']}" />`;
			return img;
		}),
		slideshowImages = document.querySelector(`#slideshow${uid} .slideshowImages`),

		// === DESCRIPTIONS:
		descriptions = colors.map((color, idx) => {
			let colorID = `${create_ID(color['name'])}`,
				description = `
					<div
						class="py-4 prose hidden text-left tyt-description
						${idx === 0 ? 'relative !block' : 'relative'}"
						id="tyt-description-${colorID}-${uid}">
						<h3 class="mb-2">${color['title']}</h3>
						<p>${color['description']}</p>
					</div>`;
			return description;
		}),
		slideshowDescriptions = document.querySelector(`#slideshow${uid} .slideshowDescriptions`),

		// === BUTTONS:
		try_me_classes = `
			text-[9px] text-white uppercase opacity-0 duration-200 scale-50 pointer-events-none translate-y-1`,
		buttons = colors.map((color, idx) => {
			let colorID = `${create_ID(color['name'])}`,
				buttonLabel = layout !== 'home' ? `<div class="${button_label}">${color['name']}</div>` : '',

				// THUMBNAIL || SWATCH:
				button = thumbStyle === 'thumbnail' ? `
					<div class="${wrapper_for_thumbnail_button}">
						<button id="tyt-${colorID}-${uid}"
							class="${thumbnail_button} ${idx === 0 ? 'active' : ''} tyt-${colorID}"
							style="background-image: url(/image?w=64&f=fragrance-${colorID}.png&t=${token});"
							aria-current="true"
							aria-controls="pbt-tyt-carousel">
						</button>
						${buttonLabel}
					</div>
				` : `
					<div class="${wrapper_for_swatch_button} ${layout === 'home' ? 'w-9 md:w-10 lg:w-14 xl:w-16' : 'w-16 xl:w-20'}">
						<button id="tyt-${colorID}-${uid}"
							class="${swatch_button} ${idx === 0 ? 'active' : ''} tyt-${colorID}"
							aria-current="true"
							aria-controls="pbt-tyt-carousel">
							${colorID === 'rose-gold' ? 
								`<span class="${try_me_classes}">Coming</span><span class="${try_me_classes} delay-75 block"> soon!</span>` : 
								`<span class="${try_me_classes}">Try&nbsp;</span><span class="${try_me_classes} delay-75"> me!</span>`}
						</button>
						</button>
						${buttonLabel}
					</div>
				`;
			return button;
		}),
		swatchButtons = document.querySelector(`#slideshow${uid} .swatchButtons`);

	// ADD TO DOM:
	swatchButtons.innerHTML = buttons.join('');
	slideshowImages.innerHTML = images.join('') + pillLabel;
	if (layout !== 'home') slideshowDescriptions.innerHTML = descriptions.join('');

	// START SLIDESHOW:
	start_Slideshow(`#slideshow${uid}`, layout, autoPlay, interval);
}